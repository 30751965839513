import { createGlobalStyle } from "styled-components";
import Background from "../images/background.svg";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Gotham-Black";
    src: url("https://cpxfonts.cc/Gotham-Black.otf")
      format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Gotham-Ligth";
    src: url("https://cpxfonts.cc/Gotham-Ligth.otf")
      format("opentype");
  }
  
  @font-face {
    font-family: "Gotham-Medium";
    src: url("https://cpxfonts.cc/Gotham-Medium.otf")
      format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Gotham-Thin";
    src: url("https://cpxfonts.cc/Gotham-Thin.otf")
      format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Gotham-Ultra";
    src: url("https://cpxfonts.cc/Gotham-Ultra.otf")
      format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  html, body{
    margin: 0px;
    padding: 0px;
    overflow: hidden !important;
  }

  body{
    background-image: url(${Background});
    height: 100vh;
    background-size: cover;
  }
`
export default GlobalStyle
