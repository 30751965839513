import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import ModalBg from "../images/modalBg.svg";
import Button from "../images/button.svg";
import Logo from "../images/logo.svg";
import Finger from "../images/finger.svg";

const CTA = styled.img`
  width: 250px;
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

const Anchor = styled.a`
  div {
    /* animation: pulse 2.5s infinite; */

    @keyframes pulse {
      0% {
        transform: scaleX(1);
      }
      50% {
        transform: scale3d(1.05, 1.05, 1.05);
      }
      to {
        transform: scaleX(1);
      }
    }
  }
`;
interface ModalProps {
  token: string | null;
}

const Modal: React.FC<ModalProps> = ({ token }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h1>
          Ganaste hasta <br />{" "}
          <span>
            100 giros gratis <img src={Finger} alt="" />{" "}
          </span>
        </h1>
        <div className="offer-list">
          <Anchor href={`https://rodadab7k.com/?_lp=1&_token=${token}`}>
            <div>
              <span>100 Giros gratis</span> Depositar 20 Mxn
            </div>
          </Anchor>

          <div className="offer-row">
            <Anchor href={`https://rodadab7k.com/?_lp=1&_token=${token}`}>
              <div>
                <span>150 Giros gratis</span> Depositar 70 Mxn
              </div>
            </Anchor>
            <Anchor href={`https://rodadab7k.com/?_lp=1&_token=${token}`}>
              <div>
                <span>200 Giros gratis</span> Depositar 175 Mxn
              </div>
            </Anchor>
          </div>
        </div>

        <div>
          <img src={Logo} />
        </div>

        <a
          className="finish-button"
          href={`https://rodadab7k.com/?_lp=1&_token=${token}`}
        >
          <CTA src={Button} />
        </a>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); // Dark background with 50% opacity

  z-index: 1000;
`;

const ModalContent = styled.div`
  display: flex;
  width: 90vw;
  height: 78vh;
  padding: 40px 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 105px;
  gap: 25px;
  text-align: center;
  position: relative;
  background-image: url(${ModalBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  h1 {
    font-family: "Gotham-Medium";
    color: white;
    font-size: 25px;

    span {
      font-size: 30px;
      font-family: "Gotham-Black";
    }
  }

  .offer-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 1rem;

    @media (max-width: 375px) {
      a {
        div {
          font-size: 0.75rem;
        }
      }
    }

    .offer-row {
      display: flex;
      width: 100%;
      gap: 1rem;

      a {
        div {
          span {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  a {
    display: flex;
    width: 100%;
    text-decoration: none;
    color: white;
    font-family: "Gotham-Thin";

    div {
      padding: 10px 0px;
      border: 1px solid #cdc5c5;
      border-radius: 8px;

      display: flex;
      flex-direction: column;
      width: 100%;

      span {
        text-transform: uppercase;
      }
    }
  }

  div {
    button {
      background: none;
      border: none;
    }
  }

  span {
    font-family: "Gotham-Black";
  }

  @media screen and (min-width: 768px) {
    max-width: 450px;
  }

  h2 {
    color: white;
    font-family: "Gotham-Black";
    font-size: 22px;
  }

  p {
    color: #7d7d84;
    font-family: "Gotham-Medium";
  }

  .finish-button {
    display: flex;
    justify-content: center;
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #7d7d84;
`;
