import styled from "styled-components";
import HeadlineImg from "../images/headline.svg"

// Create a styled img component
const HeadImg = styled.img`
  width: 100px;
  display: block;
  margin: auto;
  padding: 10px 0px;

  @media screen and (min-width: 768px){
    width: 200px;
  }
`;

export default function Headline() {
  return (
    <>
      <HeadImg src={HeadlineImg} />
    </>
  );
}
