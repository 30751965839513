import React, { useEffect, useState } from "react";
import ScratchCard from "./ScratchCard/scratchCardGame";
import Background from "./ScratchCard/scratchCardBG";
import Modal from "./Modal/Modal";
import GlobalStyle from "./Fonts/GlobalStyle";
import styled, { keyframes } from "styled-components";
import Headline from "./Headline/Headline";
import Counter from "./counter/counter";
import { useLocation, useNavigate } from "react-router-dom";
import Frame from "./images/frame.svg";
import Base from "./images/base.svg";
import Cover from "./images/cover.svg";

const Main = styled.div`
  position: relative;
`;
const Scratch = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const CardDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  width: 100%;
  background-image: url(${Frame});
  background-size: cover;

  @media screen and (min-width: 768px) {
    width: 414px;
    display: block;
    margin: auto;
  }
`;

const ScratchCardDiv = styled.div`
  margin-top: 140px;
`;

const BackgroundDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonDiv = styled.div`
  background-image: url(${Base});
  background-size: 100% 100%;
  display: block;
  margin: 15px auto 0px auto;
  z-index: 10000;
  width: 100;
  height: 120px;

  button {
    background: none;
    border: none;
    display: block;
    margin: auto;

    img {
      width: 280px;
      display: block;
      margin: auto;
    }
  }

  @media screen and (min-width: 768px) {
    width: 400px;
  }
`;

const scratchAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-55deg); }
  100% { transform: rotate(0deg); }
`;

const Btn = styled.div`
  // background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/da5ac9a3-4e44-4e00-b113-753020d06100/public);
  width: 310px;
  height: 50px;
  background-size: 100% 100%;
  margin: 5px 0px;
  position: relative;
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

// Use the defined keyframes in your styled component
const Hand = styled.img`
  width: 20px !important;
  position: absolute;
  right: 45px;
  top: 15px;
  animation: ${scratchAnimation} 2s infinite;
`;

const App: React.FC = () => {
  const [showModal, setShowModal] = useState(true);
  const [scratchedPercent, setScratchedPercent] = useState(0);
  const [animate, setAnimate] = useState(false); // State to control animation
  const [cover, setCover] = useState(true);
  const [btn, setBtn] = useState(true);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get("_token");
  const [token, setToken] = useState<string | null>(tokenFromUrl);

  useEffect(() => {
    if (!token) {
      const newToken = "uuid_1m0tuj63cs76_1m0tuj63cs7666770fa90d6db1.35294397";
      setToken(newToken);

      queryParams.set("_token", newToken);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [token, navigate, window.location.pathname, window.location.search]);

  const handleScratch = (scratched: boolean) => {
    if (scratched) {
      setTimeout(() => {
        setShowModal(true);
        setBtn(false);
      }, 2000);
      setAnimate(true);
    }
  };

  const handleScratchedPercentChange = (percent: number) => {
    setScratchedPercent(percent);
    if (percent > 70) {
      handleScratch(true);
    }
  };

  const handleClick = () => {
    setCover(false);
    setAnimate(true);
    setTimeout(() => {
      setShowModal(true);
    }, 5000);
  };

  return (
    <Main>
      <Counter />
      <GlobalStyle />
      <Headline />
      <Scratch>
        <BackgroundDiv>
          <Background onScratch={handleScratch} animate={animate} />
        </BackgroundDiv>
        <CardDiv>
          <ScratchCardDiv>
            {cover && (
              <ScratchCard
                width={350}
                height={350}
                image={Cover}
                brushSize={30}
                onScratch={handleScratch}
                onScratchedPercentChange={handleScratchedPercentChange}
              />
            )}
          </ScratchCardDiv>
        </CardDiv>
      </Scratch>
      <ButtonDiv>
        {btn && (
          <button>
            <Btn></Btn>
          </button>
        )}
      </ButtonDiv>
      {showModal && <Modal token={token} />}
    </Main>
  );
};
export default App;
